import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { SidebarService } from "../../../core/services/misc/sidebar.service";
import { Router } from "@angular/router";
import { ChannelingCenterService } from 'src/app/core/services/curd/channeling-center.service';
import { TimeService } from 'src/app/core/services/curd/time.service';
import {AuthService} from '../../../core/services/misc/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  display_logo = true;

  current_date = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');
  bookings: any = [];
  current_patient: any;
  selected_date: any;
  hospitals: any = [];
  selected_hospital = 'Select Channeling Center';
  selected_slot = 'Select Slot';
  slot_list: any = [];

  constructor(
    private sidebar: SidebarService,
    private router: Router,
    private hospital_service: ChannelingCenterService,
    private slot_service: TimeService,
    private auth: AuthService
  ) {
    SidebarComponent.resizeEvent();
    SidebarComponent.menuToggleOnResize();
  }

  ngOnInit(): void {
    this.fetchBookings(true);
    this.getBookings();
    this.getHospitals();
  }

  getBookings() {
    setInterval(() => {
      this.fetchBookings();
    }, 2500);
  }

  resetSelects() {
    this.selected_hospital = 'Select Channeling Center';
    this.selected_slot = 'Select Slot';
  }

  getHospitals() {
    this.hospital_service.list().then(hospitals => {
      this.hospitals = hospitals;
      //filter allowed locations for nurse or jr doctor
      let logged_user_type = this.getLoggedUserType();
      if (logged_user_type != 'Consultant') {
        this.hospitals = [];
        let allowed_locations = this.auth.getAllowedLocations();
        for (let location of allowed_locations) {
          // @ts-ignore
          let filtered_data = hospitals.filter(obj => obj._id == location.hospital_id)[0];
          this.hospitals.push(filtered_data);
        }
      }
      //end filter allowed locations
    }).catch(e => {

    })
  }

  getLoggedUserType() {
    return this.auth.getUserType();
  }

  getTimeSlots() {
    this.slot_service.list().then(list => {
      //@ts-ignore
      this.slot_list = list.filter(obj => obj.channeling_center_name == this.selected_hospital && obj.date == this.current_date)

    }
    ).catch(e => console.log(e))
  }

  fetchBookings(store_slot = false) {

    if (this.current_date != this.selected_date) {
      this.selected_date = null;
      this.current_patient = null;
    }
    this.sidebar.getBookings(this.selected_slot).then(res => {
      this.bookings = res;
      if (store_slot) {
        this.storeData(res[0]);
      }
    })
  }

  loadDashboard(patient_id: any, visit_doc_id, visit_type, item, index) {
    this.current_patient = index;
    this.selected_date = this.current_date;
    this.storeData(item);
    if (visit_type == 'visit') {
      this.router.navigate(['dashboard', patient_id]);
    } else {
      this.edit(visit_doc_id);
    }
  }

  private static menuToggleOnResize() {
    let window_width = window.innerWidth;
    if (window_width < 1025) {
      document.getElementsByTagName('body')[0].classList.add('enlarge-menu', 'enlarge-menu-all');
    } else if (window_width < 1340) {
      document.getElementsByTagName('body')[0].classList.remove('enlarge-menu-all');
      document.getElementsByTagName('body')[0].classList.add('enlarge-menu');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('enlarge-menu', 'enlarge-menu-all');
    }
  }

  private static resizeEvent() {
    window.addEventListener('resize', function () {
      let window_width = window.innerWidth;
      if (window_width < 1025) {
        document.getElementsByTagName('body')[0].classList.add('enlarge-menu', 'enlarge-menu-all');
      } else if (window_width < 1340) {
        document.getElementsByTagName('body')[0].classList.remove('enlarge-menu-all');
        document.getElementsByTagName('body')[0].classList.add('enlarge-menu');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('enlarge-menu', 'enlarge-menu-all');
      }
    });
  }
  edit(document_id: string) {
    this.router.navigate(['appointments/edit', document_id]);
  }

  storeData(item: any) {
    localStorage.setItem('slot', JSON.stringify(item));
  }
}
